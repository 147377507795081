import React, {useEffect} from "react";
import "../styles/theme.min.css"

    const ContactusCorporate = props => {
        const { region, portalId, formId } = props;
        useEffect(() => {
            const script = document.createElement('script');
            script.src='https://js.hsforms.net/forms/shell.js';
            document.body.appendChild(script);
    
            script.addEventListener('load', () => {
                // @TS-ignore
                if (window.hbspt) {
                    // @TS-ignore
                     window.hbspt.forms.create({
                     region: 'na1',
                     portalId: '19641921',
                     formId: 'e07975cb-71dc-47d8-ab6c-f57bbcdb1413',
                     target: '#hubspotFormCorp'
                    })
                }
            });
        }, []);
    
        return (
            <div id="contactCorp">
                <div id="hubspotFormCorp"></div>
            </div>
        );
    };
    
    export default ContactusCorporate;