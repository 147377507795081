import React from "react"
import axios from "axios"
import * as qs from "query-string"

import FormEmbed from "./formembed"

class StudentGen extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null, lastSubmitTime: null }
  }

 handleSubmit(event) {
    if (this.state.lastSubmitTime && Date.now() - this.state.lastSubmitTime < 60000) {
      this.setState({ feedbackMsg: "Please wait for at least 1 minute before submitting again." })
      event.preventDefault()
      return
    }
    // Do not submit form via HTTP, since we're doing that via XHR request.
    event.preventDefault()
  // Loop through this component's refs (the fields) and add them to the
  // formData object. What we're left with is an object of key-value pairs
  // that represent the form data we want to send to Netlify.
  const formData = {}
  Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))

  // Set options for axios. The URL we're submitting to
  // (this.props.location.pathname) is the current page.
  const axiosOptions = {
    url: "/membership/",
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify(formData),
  }

  // Submit to Netlify. Upon success, set the feedback message and clear all
  // the fields within the form. Upon failure, keep the fields as they are,
  // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then(response => {
        this.setState({
          feedbackMsg: "Thank you for your interest in the BBA! Please check your inbox for an invoice.",
          lastSubmitTime: Date.now(), // Update the lastSubmitTime
        })
        let formSubmit = document.getElementById("lg")
        formSubmit.setAttribute('style', 'display:none')
      })
      .catch(err =>
        this.setState({
          feedbackMsg: "Form could not be submitted.",
        })
      )
  }


  render() {
    return (
	    <FormEmbed>
		
        {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}

        <form ref={this.domRef} name="Student Gen" method="POST" data-netlify="true" id="lg" onSubmit={event => this.handleSubmit(event)}>
          <input ref="form-name" type="hidden" name="form-name" value="Student Gen" /> 
  <p>
    <span><input type="text" className="sub-form-input" placeholder="First Name *" ref="first-name" name="first-name" required /> <input type="text" className="sub-form-input" placeholder="Last Name *" ref="last-name" name="last-name"  required /></span>
  </p>
  <p>
    <span><input type="email" className="sub-form-input-full" placeholder="Email (.edu email is required) *" ref="email" name="email" pattern="\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(edu)\b" title="A valid .edu email is required" required /></span>
  </p>	 	
  <p>	 	
	<span><input type="text" className="sub-form-input-full" placeholder="Location" ref="location" name="location" /></span>
   </p> 
  <p>
    <span><input type="text" className="sub-form-input-full" placeholder="School *" ref="school" name="school" required /></span> 
  </p>
  <p>  
	<span><select className="sub-form-input-full" name="edu" id="education-type" required><option value="undergraduate">Undergraduate *</option><option value="graduate">Graduate</option><option value="highschool">High School</option></select></span>
  </p>
  <p><span className="price-content">Membership Total:</span><span className="price-numbers"> $25</span></p>
  <p>
    <button className="btn-new" type="submit">Submit</button>
  </p>
        </form>
	</FormEmbed>	
    )
  }
}

export default StudentGen