import React, { useState } from "react"
import { graphql } from "gatsby"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import {loadStripe} from "@stripe/stripe-js"
import Leadgen from "../components/leadgen"
import Studentgen from "../components/studentgen"
import ContactusCorporate from "../components/contactuscorporate"
import Layout from "../components/layout"
import "../styles/reach.css"

export default function BecomeMemberTemplate() {

  const stripePromise = loadStripe("pk_live_0XOq9mfEvF4n6dqdYaSWTKYQ00ztdEAkZE");
  
  const productIndividual = {
	  price: "plan_EtSZlG0oDj5y5F",
	  quantity: 1
	  };


  const [tabIndex, setTabIndex] = useState(0);
  
  const handleCorporateSignUpClick = () => {
    setTabIndex(0); 
	};
  const handleIndSignUpClick = () => {
    setTabIndex(1); 
	};
	const handleStudentSignUpClick = () => {
    setTabIndex(2); 
	};

  return (
    <Layout breadcrumbs={["Become A Member"]}>
    <div className="carousel-container">
      <div className="group-5-about-us" >
      <div className="connecting-the-blockchain-together" >Become a Member</div>
      <p className="about-us-carousel">The Boston Blockchain Association is a volunteer-led organization that is funded entirely by our members and sponsor companies. There are many benefits to becoming a member -- see below.</p>
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705618980/bba-become-a-member-header_jkgwxm.jpg" className="group-3" />
    </div> 	
    <div className="container-noflex">    
  <div className="pricing-table">
    <div className="plan">
      <h2>Start-up</h2>
      <ul>
        <li>Exclusive In-Person Events - ✓<br /><span className="become-a-member-desc">Power up your networking and knowledge</span></li>
        <li>Exclusive Online Workshops - ✓<br /><span className="become-a-member-desc">Learning opportunities in an interactive setting</span></li>
        <li>Professional Development - ✓<br /><span className="become-a-member-desc">Training sessions with leading analysts, researchers, and thought leaders</span></li>
        <li>Community Access - ✓<br /><span className="become-a-member-desc">Build and promote your projects throughout New England and beyond</span></li>
        <li>Talent Pipeline - ✓<br /><span className="become-a-member-desc">Connect with new student talent coming out of top Boston colleges and universities</span></li>
        <li>Enterprise Innovation - ✓<br /><span className="become-a-member-desc">Plug into our pipeline of new blockchain ideas</span></li>
      </ul>
      <div className="price-corp"><stripe-buy-button buy-button-id="buy_btn_1P99lFBzDDb3pCqJNdeFzdll" publishable-key="pk_live_0XOq9mfEvF4n6dqdYaSWTKYQ00ztdEAkZE"></stripe-buy-button></div>
    </div>
    <div className="plan">
      <h2>Individual</h2>
      <ul>
       <li>Exclusive In-Person Events - ✓<br /><span className="become-a-member-desc">Power up your networking and knowledge</span></li>
        <li>Exclusive Online Workshops - ✓<br /><span className="become-a-member-desc">Learning opportunities in an interactive setting</span></li>
        <li>Professional Development - ✓<br /><span className="become-a-member-desc">Training sessions with leading analysts, researchers, and thought leaders</span></li>
      </ul>
      <div className="price-ind"><stripe-buy-button buy-button-id="buy_btn_1P997zBzDDb3pCqJfhPq28Ur" publishable-key="pk_live_0XOq9mfEvF4n6dqdYaSWTKYQ00ztdEAkZE"></stripe-buy-button></div>     
    </div>
    <div className="plan">
      <h2>Student</h2>
      <ul>
       <li>Exclusive In-Person Events - ✓<br /><span className="become-a-member-desc">Power up your networking and knowledge</span></li>
        <li>Exclusive Online Workshops - ✓<br /><span className="become-a-member-desc">Learning opportunities in an interactive setting</span></li>
      </ul>
      <div className="price-student"><stripe-buy-button buy-button-id="buy_btn_1P99iABzDDb3pCqJ3eFZAo4m" publishable-key="pk_live_0XOq9mfEvF4n6dqdYaSWTKYQ00ztdEAkZE"></stripe-buy-button></div>  
    </div>
   </div>  
   </div> 
  <div className="container-noflex-membership" id="membership">  
	<p><center>For information on corporate memberships and sponsorship packages, <a href="/contact-us">contact us.</a></center></p>
	</div>
      </Layout>
  )
}